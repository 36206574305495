import React from 'react'
import PropTypes from 'prop-types'
import ReferenceTile from './ReferenceTile'

const propTypes = {
  works: PropTypes.arrayOf(PropTypes.object),
}

const WorkTeaserIntro = ({ works }) => (
  <>
    {works.map(({ node }) => {
      return (
        <ReferenceTile
          key={node.id}
          fluid={node.teaserData.preview.asset.fluid}
          tagline={node.title}
          claim={node.teaserData.subtitle}
          target={`/projekte/${node.slug.current}/`}
        />
      )
    })}
  </>
)

WorkTeaserIntro.propTypes = propTypes

export default WorkTeaserIntro
