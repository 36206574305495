import React from 'react'
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui'
import styled from '@emotion/styled'
import Video from 'react-responsive-video'

const StyledClaim = styled.div`
  color: #fff;
  .first {
    display: none;
  }
  @media (min-width: 900px) {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    .first {
      display: block;
    }
  }
`

const StyledWrapper = styled.div`
  background: #000;
  position: relative;
  video {
    height: 100%;
    opacity: 0.7;
  }
`

const VideoAndClaim = ({ children, fluid, fluidDark, ...props }) => {
  const { colorMode } = useThemeUI()

  return (
    <StyledWrapper className={props.className}>
      <Video
        fluid={colorMode === `default` ? fluid : fluidDark ? fluidDark : fluid}
        mp4={props.mp4}
        objectFit={`cover`}
        height={props.height}
      />
      <StyledClaim sx={{ variant: `styles.padding` }}>
        <div sx={{ variant: `twoColumn` }}>{children}</div>
      </StyledClaim>
    </StyledWrapper>
  )
}

export default VideoAndClaim
