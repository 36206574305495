import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
/** @jsx jsx */
import { jsx, Styled } from 'theme-ui'
import Layout from '../components/Layout'
import GridReference from '../components/GridReference'
import SEO from '../components/Seo'
import WorkTeaserIntro from '../components/WorkTeaserIntro'
import VideoAndClaim from '../components/VideoAndClaim'
import helloFour from '../images/home/4fb-Website.mp4'

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />

    <div sx={{position: `relative`}}>
      <VideoAndClaim mp4={helloFour}>
        <div sx={{ gridColumn: 2}}>
          {/* In Sanity also configure color */}
          <Styled.h1>Gemeinsam Dinge verändern</Styled.h1>
          <Styled.h3>
            Wir begleiten die Digitalisierung Ihres Unternehmens. Schritt für Schritt
          </Styled.h3>
        </div>
      </VideoAndClaim>
        <div className="banner"  sx={{
            position: `absolute`,
            left: `-1px`,
            top: 0,
            zIndex: 9,

        }}
        >
            <Link
                to="/stefan-kempe">
                <svg width="294px" height="297px" viewBox="0 0 294 297" version="1.1">
                    <title>Artboard Copy</title>
                    <desc>Created with Sketch.</desc>
                    <g id="Artboard-Copy" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <polygon id="Rectangle" fill="#000000" transform="translate(98.341146, 101.743519) rotate(45.000000) translate(-98.341146, -101.743519) " points="161.675888 -105.498635 167.922131 308.985673 30.6289276 171.69247 28.7601606 27.4170921"></polygon>
                        <path d="M1,124 L123,1" id="Line" stroke="#FFFFFF" stroke-linecap="square"></path>
                        <path d="M1,271.854385 L272,0.5" id="Line-Copy" stroke="#FFFFFF" stroke-linecap="square"></path>
                        <text id="In-Gedenken-an-Stefa" transform="translate(98.000000, 101.985612) rotate(-45.000000) translate(-98.000000, -101.985612) " font-family="NittiGrotesk-Normal, Nitti Grotesk" font-size="20" font-weight="normal" fill="#FFFFFF" text-decoration="underline">
                            <tspan x="7.58" y="106.985612">In Gedenken an Stefan</tspan>
                        </text>
                    </g>
                </svg>
            </Link>
        </div>
    </div>

    <div sx={{ variant: `twoColumn` }}>
      <Img fluid={data.heroImage01.childImageSharp.fluid} sx={{ minHeight: '100%' }} />
      <div sx={{ variant: `styles.padding` }}>
        <Styled.h2 as="h2">Wir denken die Dinge weiter</Styled.h2>
        <p>
          Wir sind die Agentur für die Optimierung Ihrer Webapplikation. Der Begriff
          <i> Projektabschluss </i>kommt bei uns daher nicht vor. Nach dem Release ist vor dem
          Release.
          <br />
          <br />
          Wir glauben an die nachhaltige Wirkung stetiger Veränderung. Anstatt alle paar Jahre einen
          großen Relaunch um des Relaunchs Willen zu vollführen, gestalten wir den Wandel Schritt
          für Schritt und gewinnen dabei kontinuierlich neue Erkenntnisse, wie wir Dinge weiter
          verbessern können. Alles basierend auf Daten und unserer Erfahrung aus 20 Jahren
          Webgestaltung.
        </p>
        <Link
          to="/arbeitsweise"
          sx={{
            variant: `buttons`,
            display: `inline-block`,
            mb: '1.45rem',
          }}
          className="primary"
        >
          Unsere Arbeitsweise
        </Link>
      </div>
    </div>

    <div sx={{ variant: `twoColumn` }}>
      <div sx={{ variant: `styles.padding` }}>
        <Styled.h2 as="h2">Niemand hat gesagt, dass es einfach wird</Styled.h2>
        <p>
          Unternehmen brauchen heute mehr denn je die Fähigkeit zu reagieren und sich anzupassen.
          Unser Prozess basiert auf einem umfassenden Verständnis der Herausforderungen, denen
          unsere Kunden gegenüberstehen.
          <br />
          <br />
          Mit unserem Team aus Strategen, Konzeptern, Entwicklern, Projektmanagern,
          Tracking-Spezialisten und Online-Marketing-Profis gestalten wir mit Ihnen gemeinsam die
          Digitalisierung Ihres Unternehmens.
        </p>
        <Link
          to="/agentur/"
          sx={{
            variant: `buttons`,
            display: `inline-block`,
            mb: '1.45rem',
          }}
          className="primary"
        >
          Unsere Agentur
        </Link>
      </div>
      <Img fluid={data.heroImage02.childImageSharp.fluid} sx={{ minHeight: '100%' }} />
    </div>

    <div
      sx={{
        background: `#000`,
        color: '#fff',
        variant: `fourColumn`,
        my: 0,
      }}
    >
      <div sx={{ variant: `styles.padding` }}>
        <Styled.h2 as="h2">Digitaler Erfolg bedeutet Zusammenarbeit</Styled.h2>
        <p>
          Unser Geschäft ist auf Beziehungen aufgebaut - das klingt zwar klischeehaft, aber es ist
          wahr. Wir glauben an echte Partnerschaft mit messbaren Ergebnissen. Aus diesem Grund sind
          viele unserer Kunden schon seit vielen Jahren bei uns.
        </p>
      </div>
    </div>

    <GridReference bg={'#000'} large reference padding={'0'} height={`80vh`}>
      <WorkTeaserIntro works={data.projectTiles.edges} />
    </GridReference>
  </Layout>
)

IndexPage.propTypes = {
  data: PropTypes.object,
}

export default IndexPage

export const query = graphql`
  query {
    projectTiles: allSanityProjects(limit: 6, sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          teaserData {
            subtitle
            preview {
              asset {
                fluid(maxWidth: 800) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }

    workTiles: allSanityWork(
      limit: 6
      filter: { featured: { eq: true } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          bg {
            hex
          }
          clientName
          projectName
          claim
          slug {
            current
          }
          tileImage {
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    heroImage01: file(relativePath: { eq: "home/TheMoreYouWeigh.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    heroImage02: file(relativePath: { eq: "home/Surfer.png" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`
