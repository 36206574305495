import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
/* @jsx jsx */
import { jsx } from 'theme-ui'
import { Text } from '@theme-ui/components'
import styled from '@emotion/styled'

const StyledTile = styled.div`
  display: block;
  position: relative;
  text-decoration: none;
  overflow: hidden;

  & > div:first-of-type {
    opacity: 0.5;
  }

  &:hover {
    h3:after {
      width: 100%;
    }
  }

  &:hover > div:first-of-type {
    transform: scale(1.1);
  }

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const StyledClaim = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  @media (min-width: 900px) {
    bottom: 25%;
  }
`

const ReferenceTile = ({ fluid, tagline, target, bg, claim }) => (
  <Link to={target}>
    <StyledTile
      sx={{
        bg: bg,
        variant: `styles.reference.tile`,
        color: `text`,
      }}
    >
      <div
        sx={{
          variant: `styles.reference.image`,
          transition: `all 0.5s ease-in`,
        }}
      >
        <Img fluid={fluid} alt={tagline} sx={{ variant: `styles.reference.hover` }} />
      </div>
      {tagline && (
        <StyledClaim>
          <div
            sx={{
              variant: `oneColumn`,
              m: 0,
            }}
          >
            <div
              sx={{
                variant: `styles.padding`,
                p: ['0 1rem', null, '0 2rem'],
              }}
            >
              <h3 sx={{ color: `background`, variant: `headlineCool` }}>{tagline}</h3>
            </div>
            <div
              sx={{
                variant: `styles.padding`,
                p: ['.5rem 1rem', null, '.5rem 2rem'],
              }}
            >
              <Text sx={{ color: `background` }} as="p">
                {claim}
              </Text>
            </div>
          </div>
        </StyledClaim>
      )}
    </StyledTile>
  </Link>
)

ReferenceTile.propTypes = {
  fluid: PropTypes.object,
  tagline: PropTypes.string,
  target: PropTypes.string,
  bg: PropTypes.string,
}

ReferenceTile.defaultProps = {
  fluid: null,
  tagline: null, // Text inside a Tile
  target: null, // Link to content-page
  bg: `text`, // background fade color, default text-black
}

export default ReferenceTile
